<template>
  <div class="list-wrap wrapper">
    <BaseLoad v-if="loading" />
    <div class="list" v-else>
      <h2>我的工作台</h2>
      <div class="my-workplace">
        <div class="between">
          <div class="sub-nav">
            <li
              v-for="(item, index) in subTab"
              :key="item"
              :class="[index == 1 ? 'sub-act' : '']"
              @click="changeSubTab(index)"
            >
              {{ item }}
            </li>
          </div>
          <p class="sub-tip">
            <span>游戏</span>
            <span class="sub-tip-total"> {{ total }} </span>个
          </p>
        </div>
        <div class="line"></div>
        <ListLoad v-if="listLoad && !loading" />
        <!-- 游戏 -->
        <ul class="game-list" v-if="!listLoad && list.length > 0">
          <li class="game-item" v-for="item in list" :key="item.config_id">
            <p class="delete flex">
              <span @click.stop="handleDel(item.wb_id)">
                <i class="iconfont icon-shanchu"></i>
                删除
              </span>
            </p>
            <img
              class="cover"
              :src="preSrc + item.cover"
              alt=""
              @click.prevent="
                previewCreateGame(item.game_id, item.project_id, item.name)
              "
            />
            <h2 :title="item.name" class="ellipsis">{{ item.name }}</h2>
          </li>
        </ul>
      </div>
      <BaseEmpty v-if="!listLoad && !loading && list.length == 0" />
      <!-- 分页 -->
      <div class="pagination-con" v-if="list.length > 0 && total > 6">
        <el-pagination
          :page-size="pageSize"
          :pager-count="5"
          layout="prev, pager, next"
          :total="total"
          :current-page="page"
          @current-change="handleCurrentChange"
        >
        </el-pagination>
      </div>
      <!-- 分页end -->
    </div>
  </div>
</template>
<script>
import BaseEmpty from "@/components/empty/BaseEmpty.vue";
import BaseLoad from "@/components/loading/BaseLoading.vue";
import ListLoad from "@/components/loading/ListLoading.vue";
import { handlePreview, handlePreviewCreate } from "@/tools/GlobalFunction";
export default {
  components: {
    BaseEmpty,
    BaseLoad,
    ListLoad,
  },
  data() {
    return {
      preSrc: config.src,
      listLoad: false, //list loading
      loading: true, //整体loading
      subTab: ["课件", "游戏"],
      subIndex: 1,
      list: [],
      page: 1, //当前页数
      total: 0, //总页数
      pageSize: 6,
    };
  },
  created() {
    let _self = this;
    _self.workplaceList(1);
  },
  methods: {
    //我的工作台
    workplaceList(page) {
      let _self = this;
      _self.axios
        .post(
          config.url.myWorkplace,
          _self.$qs.stringify({ page, size: _self.pageSize, type: 2 })
        )
        .then((res) => {
          if (res.code == 200) {
            let list = res.data.list;
            list.forEach((item) => (item.show = false));
            _self.list = list;
            _self.total = res.data.total;
            _self.loading = false;
            _self.listLoad = false;
          } else if (res.code != 200 && res.code != 401) {
            _self.showTip("error", res.msg);
            _self.loading = false;
            _self.listLoad = false;
          }
        });
    },
    // 切换子选项卡
    changeSubTab(index) {
      let _self = this;
      if (index == 1) {
        return;
      }
      _self.$router.push({
        name: "my-workplace",
      });
    },
    // 删除
    handleDel(id) {
      let _self = this;
      _self.axios
        .post(config.url.myWorkplaceDel, _self.$qs.stringify({ wb_id: id }))
        .then((res) => {
          if (res.code == 200) {
            res.tip == 1
              ? _self.showTip("success", res.msg)
              : _self.showTip("success", "删除成功");
            _self.page = 1;
            _self.workplaceList(_self.page);
          } else if (res.code != 200 && res.code != 401) {
            _self.showTip("error", res.msg);
          }
        });
    },
    previewCreateGame(gameId, configId, title) {
      let _self = this;
      // console.log(gameId, configId, title)
      handlePreviewCreate(gameId, configId, title, _self);
    },
    // 改变分页
    handleCurrentChange(page) {
      let _self = this;
      _self.page = page;
      _self.workplaceList(page);
    },
  },
};
</script>
<style lang="scss" scoped>
.container {
  background: #f9f9f9;
  .content {
    background: #fafafa;
  }
}
.con {
  display: flex;
  margin-top: 60px;
  background: #f9f9f9;
  .tab {
    width: 190px;
    height: 440px;
    box-sizing: border-box;
    margin-right: 30px;
    border-radius: 10px;
    background: #fff;
    h3 {
      font-size: 20px;
      font-weight: bold;
      padding: 30px 15px;
      box-sizing: border-box;
    }
    h3::before {
      content: "";
      display: inline-block;
      background: url("../../assets/img/home/pre-icon.png") center center
        no-repeat;
      background-size: 100%;
      width: 10px;
      height: 10px;
      margin-right: 6px;
    }
    li {
      padding: 15px 35px;
      box-sizing: border-box;
      // width: 100%;
      border-right: 2px solid #fff;
      margin-bottom: 20px;
    }
    li:hover {
      cursor: pointer;
      color: $color-main;
      border-right: 2px solid $color-main;
      background: #fff7f1;
    }
    li.active {
      color: $color-main;
      border-right: 2px solid $color-main;
      background: #fff7f1;
    }
  }
  .list {
    width: 980px;
    padding: 30px;
    box-sizing: border-box;
    background: #fff;
    border-radius: $border-radius-main;
    h2 {
      margin-bottom: 40px;
    }
  }
  .item {
    .item-l {
      width: 200px;
      height: 130px;
      object-fit: contain;
      flex-shrink: 0;
      flex-grow: 0;
      border-radius: $border-radius-main;
      cursor: pointer;
    }
    .item-r {
      width: 660px;
      height: 130px;
      flex-shrink: 0;
      flex-grow: 0;
      padding: 4px 30px 10px;
      box-sizing: border-box;
      border-left: 0;
      border-radius: 0 10px 10px 0;
      display: flex;
      flex-direction: column;
      .item-name-desc {
        cursor: pointer;
      }
      h3 {
        font-size: 20px;
        font-weight: bold;
        margin-bottom: 15px;
      }
      .desc {
        font-size: 16px;
        color: $font-color-51;
        margin-bottom: 30px;
      }
      .price {
        font-size: 20px;
        color: $color-main;
        font-weight: bold;
      }

      .itme-r-b {
        background: $font-color-9f;
      }
    }
  }
  .icon-shoucang,
  .icon-star-filled {
    color: #f9e457;
    margin-right: 7px;
  }
  .icon-shoucang,
  .icon-star-filled {
    color: #f9e457;
    margin-right: 7px;
  }
  .favor i,
  .work img,
  .btn:hover,
  .sub-nav:hover {
    cursor: pointer;
  }
  .favor-work {
    color: $font-color-9f;
    justify-content: flex-start;
    .icon-yulan {
      color: #34c3f9;
      margin: 3px 6px 0 0;
    }
    .slash {
      margin: 0 10px;
    }
    .favor {
      margin-top: -3px;
    }
    .favor i {
      margin-right: 6px;
    }
    .work {
      display: flex;
      align-items: center;
      img {
        display: inline-block;
        width: 18px;
        height: 18px;
        object-fit: contain;
        margin-right: 10px;
      }
    }

    .icon-bianji1 {
      color: #03cca6;
    }
  }
  .delete {
    cursor: default;
    span {
      cursor: pointer;
    }
    .icon-shanchu {
      cursor: pointer;
      color: #ff4d4f;
      margin-right: 2px;
    }
  }

  // 已购课程
  .course-list-con {
    width: 920px;
    margin-top: 30px;
    display: flex;
    flex-shrink: 0;
    flex-grow: 0;
    flex-wrap: wrap;
    justify-content: flex-start;
    .delete {
      cursor: default;
      margin-bottom: 4px;
      justify-content: flex-end;
      color: $font-color-9f;
      span {
        cursor: pointer;
      }
      .icon-shanchu {
        cursor: pointer;
        color: #ff4d4f;
        margin-right: 2px;
      }
    }
  }
  .course-list-item {
    margin-right: 40px;
    .course-list-favor-work {
      width: 165px;
      margin-left: 100px;
      justify-content: flex-end;
      display: none;
    }
    li {
      cursor: pointer;
      width: 275px;
      height: 187px;
      overflow: hidden;
      position: relative;
      margin: 0 0 20px 0;
      border-radius: $border-radius-main;
      box-shadow: 0px 3px 3px rgba(0, 0, 0, 0.05);
      // transition: 0.4s ease-in-out;
      p {
        font-size: 16px;
        z-index: 3;
        color: #fff;
        position: absolute;
        bottom: 0;
        left: 0;
        padding: 13px 20px;
        width: 100%;
        text-align: center;
        background: rgba(0, 0, 0, 0.5);
        box-sizing: border-box;
        border-radius: 0 0 10px 10px;
      }
    }
  }
  .course-list-item:nth-child(3n),
  .course-list-item:last-of-type {
    margin-right: 0;
  }
  .course-list {
    display: flex;
    align-items: flex-start;
    position: relative;
    > div {
      width: 920px;
      flex-shrink: 0;
      flex-grow: 0;
      flex-wrap: wrap;
      justify-content: flex-start;
    }
    .course-list-item:nth-child(3n),
    li:nth-child(3n),
    li:last-of-type {
      margin-right: 0;
    }
    li:hover {
      background: rgba(0, 0, 0, 0.4);
      box-shadow: 0px 3px 20px rgba(0, 0, 0, 0.05);
      transition: 0.4s;
      // margin-top: -20px;
      cursor: pointer;
      p {
        color: $font-color-main;
      }
    }
  }
  .my-game,
  .my-favor,
  .my-workplace {
    width: 920px;
    .sub-nav {
      display: flex;
      flex-wrap: nowrap;
      overflow-x: auto;
      li {
        font-size: 18px;
        color: $font-color-51;
        width: 120px;
        text-align: center;
        flex-shrink: 0;
        flex-grow: 0;
        transition: 0.4s ease-in-out;
      }
      li::after {
        content: "";
        display: block;
        margin: 20px auto 0;
        width: 50px;
        height: 5px;
        background: #f9f9f9;
        border-radius: 5px;
      }
      li.sub-act::after {
        content: "";
        display: block;
        margin: 20px auto 0;
        width: 50px;
        height: 5px;
        background: $color-main;
        border-radius: 5px;
      }
    }
  }
  .line {
    width: 100%;
    height: 10px;
    background: #f9f9f9;
    margin-top: -8px;
    border-radius: 5px;
  }
  .game-list {
    display: flex;
    flex-wrap: wrap;
    margin-top: 30px;
    .game-item {
      width: 280px;
      height: 256px;
      padding: 20px;
      box-sizing: border-box;
      border-radius: $border-radius-main;
      box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.05);
      margin: 0 40px 20px 0;
      .cover {
        width: 160px;
        height: 160px;
        object-fit: contain;
        margin: 0 auto 16px;
        cursor: pointer;
      }
      h2 {
        font-size: 24px;
        color: $font-color-main;
        margin: 0 auto 24px;
        text-align: center;
      }
      .delete {
        justify-content: flex-end;
      }
    }
    .game-item:nth-child(3n) {
      margin: 0 0 20px 0;
    }
  }
  .my-favor-con {
    margin-top: 30px;
  }

  .sub-tip {
    font-size: 18px;
    color: $font-color-51;
    .sub-tip-total {
      color: $color-main;
    }
  }
  .delete {
    color: $font-color-9f;
  }
  .buy {
    width: 60px;
    height: 24px;
    line-height: 24px;
    text-align: center;
    color: #34c3f9;
    background: #eefaff;
    border: 1px solid #34c3f9;
    border-radius: 5px;
  }
  .hide {
    opacity: 0;
  }
}
</style>
